import { Override } from "../types";
import { dateToStr } from "../utils/dates";
import {
  EventColor as EventColorDto,
  Project as ProjectDto,
  ProjectInclude as ProjectIncludeDto,
  Smurf as SmurfDto,
} from "./client";
import { EventColor } from "./EventMetaTypes";
import { EventKey } from "./Events";
import { TransformDomain } from "./types";

export enum IncludeProject {
  Full = ProjectIncludeDto.FULL,
  Id = ProjectIncludeDto.ID,
}

export enum Smurf {
  Prioritize = SmurfDto.PRIORITIZE,
  Default = SmurfDto.DEFAULT,
}

export type Project = Override<
  ProjectDto,
  {
    readonly id: number;
    readonly eventKeys?: EventKey[];
    readonly created?: Date;
    readonly updated?: Date;

    name?: string;
    priority?: Smurf;
    color?: EventColor;
  }
>;

export function dtoToProject(dto: ProjectDto): Project {
  return {
    ...dto,
    id: dto.id as number,
    color: EventColor.get(dto.color),
    priority: (dto.priority as any as Smurf) || Smurf.Default,
    created: !!dto.created ? new Date(dto.created) : undefined,
    updated: !!dto.updated ? new Date(dto.updated) : undefined,
    eventKeys: (dto.eventKeys as unknown as string[]) || [],
  };
}

export function projectToDto(project: Partial<Project>): Partial<ProjectDto> {
  return {
    ...project,
    color: project.color?.toJSON() as EventColorDto,
    priority: (project.priority as any as SmurfDto) || SmurfDto.DEFAULT,
    created: dateToStr(project.created)!,
    updated: dateToStr(project.updated)!,
    eventKeys: project.eventKeys as any,
  };
}

export class ProjectsDomain extends TransformDomain<Project, ProjectDto> {
  resource = "Project";
  cacheKey = "projects";
  pk = "id";

  public serialize = projectToDto;
  public deserialize = dtoToProject;

  list = this.deserializeResponse(() => {
    return this.api.projects.query1({ parameters: [] }, {});
  });

  get = this.deserializeResponse(this.api.projects.get3);

  save = this.deserializeResponse((project: Partial<Project>) => {
    return this.api.projects.create1(this.serialize(project) as ProjectDto);
  });

  update = this.deserializeResponse((project: Project) =>
    this.api.projects.put1(project.id as number, this.serialize(project) as ProjectDto)
  );

  // associateEvent = this.deserializeResponse(this.api.projects.associateEvent);
  // disassociateEvent = this.deserializeResponse(this.api.projects.disassociateEvent);
  associateTask = this.deserializeResponse(this.api.projects.associateTask);
  disassociateTask = this.deserializeResponse(this.api.projects.disassociateTask);

  delete(project: Project) {
    return this.api.projects.delete4(project.id as number);
  }
}

export const defaultProject: Omit<Project, "id"> = {
  name: "",
  color: EventColor.Graphite,
  priority: Smurf.Default,
};
